var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.nextAvailableTime && _vm.nextAvailableTime.dateTime
    ? _c(
        "div",
        {
          staticClass:
            "flex w-full items-center justify-between rounded-lg border py-4 px-4 hover:border-gray-500 hover:bg-gray-100",
          on: {
            click: function ($event) {
              return _vm.$emit("next", _vm.nextAvailableTime)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "flex items-center" },
            [
              _c("UserAvatar", { attrs: { size: 5, avatar: _vm.avatar } }),
              _c("div", { staticClass: "px-8" }, [
                _c("h3", { staticClass: "-mb-2 text-4xl font-bold" }, [
                  _vm._v(" " + _vm._s(_vm.dateTime) + " "),
                ]),
                _c("p", { staticClass: "text-base text-gray-600" }, [
                  _vm._v(" " + _vm._s(_vm.vet.display_name) + " "),
                ]),
                _vm.paymentRequired
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "text-firstvet-peachdark mr-3 text-base font-bold",
                      },
                      [_vm._v(" " + _vm._s(_vm.priceString) + " ")]
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }