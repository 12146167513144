var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c("div", { staticClass: "flex flex-col space-y-4" }, [
      _c("div", { staticClass: "flex items-center justify-between" }, [
        _c("p", { staticClass: "font-display text-xl font-semibold" }, [
          _vm._v("Services"),
        ]),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isDev,
                expression: "isDev",
              },
            ],
            staticClass: "hidden text-sm italic xl:block",
          },
          [_vm._v("POST " + _vm._s(_vm.url))]
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-col space-y-2" },
        [
          _c("base-select", {
            attrs: {
              options: _vm.animalTypes,
              value: _vm.payload.animalTypeID,
            },
            on: {
              change: function ($event) {
                ;(_vm.payload.animalTypeID = $event), _vm.fetchData()
              },
            },
          }),
          _c(
            "base-button",
            {
              attrs: {
                loading: _vm.loading,
                disabled: _vm.loading,
                color: "light",
              },
              on: { click: _vm.fetchData },
            },
            [_vm._v(" Refresh ")]
          ),
        ],
        1
      ),
      _vm.servicesWithSlot && _vm.servicesWithSlot.length
        ? _c(
            "ul",
            { staticClass: "flex flex-col space-y-4" },
            _vm._l(_vm.servicesWithSlot, function (service) {
              return _c(
                "li",
                {
                  key: service.id,
                  staticClass: "flex flex-col space-y-2 border-b p-2 pb-4",
                },
                [
                  _c("div", { staticClass: "flex flex-col space-y-1" }, [
                    _c("p", { staticClass: "mb-1 text-lg font-semibold" }, [
                      _vm._v(" " + _vm._s(service.name) + " "),
                    ]),
                    _c("p", [
                      _vm._v(
                        " " +
                          _vm._s(
                            service.service_translation
                              ? service.service_translation.description
                              : service.description
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  service.nextAvailableSlot
                    ? _c(
                        "div",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "mb-1 text-xs uppercase tracking-wide text-gray-600",
                            },
                            [_vm._v(" Next available slot ")]
                          ),
                          _c("BookableSlot", {
                            attrs: {
                              "next-available-time": service.nextAvailableSlot,
                              "date-time": _vm.formatInTimeZone(
                                new Date(
                                  service.nextAvailableSlot.dateTime.dateTime
                                ),
                                _vm.country.timeZone,
                                "HH:mm"
                              ),
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }