var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _c("div", { staticClass: "flex flex-1 flex-col" }, [
        _c(
          "div",
          { staticClass: "mb-4 flex justify-between" },
          [
            _c(
              "table-container",
              {
                staticClass: "min-h-screen",
                attrs: {
                  theads: [
                    "ID",
                    "Details",
                    "Animal types",
                    "Roles",
                    "Languages",
                    "",
                  ],
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex w-full flex-col space-y-4 pt-2",
                    attrs: { slot: "tableHeader" },
                    slot: "tableHeader",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex w-full flex-wrap md:flex-nowrap md:space-x-8",
                      },
                      [
                        _vm.digitalClinicId
                          ? _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c(
                                  "base-button",
                                  {
                                    staticClass: "flex items-center",
                                    attrs: {
                                      color: "primary",
                                      to: { name: "invite-user" },
                                    },
                                  },
                                  [
                                    _vm._v(" Invite user "),
                                    _c("font-awesome-icon", {
                                      staticClass: "ml-2",
                                      attrs: { icon: "plus" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "mt-2 w-full md:mt-0 md:max-w-sm" },
                          [
                            _c("custom-select", {
                              attrs: { options: _vm.serviceOptions },
                              on: {
                                change: function ($event) {
                                  return _vm.fetch()
                                },
                              },
                              model: {
                                value: _vm.selectedService,
                                callback: function ($$v) {
                                  _vm.selectedService = $$v
                                },
                                expression: "selectedService",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mt-2 w-full md:mt-0 md:max-w-sm" },
                          [
                            _c("custom-select", {
                              attrs: { options: _vm.animalTypeOptions },
                              on: {
                                change: function ($event) {
                                  return _vm.fetch()
                                },
                              },
                              model: {
                                value: _vm.selectedAnimalType,
                                callback: function ($$v) {
                                  _vm.selectedAnimalType = $$v
                                },
                                expression: "selectedAnimalType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "form",
                          {
                            staticClass: "inline-flex items-center space-x-1",
                            on: {
                              submit: function ($event) {
                                $event.preventDefault()
                                return _vm.fetch()
                              },
                            },
                          },
                          [
                            _c("base-input", {
                              ref: "searchInput",
                              staticClass: "min-w-80",
                              attrs: {
                                id: "search",
                                name: "search",
                                placeholder: "Search",
                              },
                              model: {
                                value: _vm.searchString,
                                callback: function ($$v) {
                                  _vm.searchString = $$v
                                },
                                expression: "searchString",
                              },
                            }),
                            _c(
                              "base-button",
                              {
                                attrs: {
                                  color: "dark",
                                  loading: _vm.loading,
                                  "data-testid": "search-button",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.fetch()
                                  },
                                },
                              },
                              [_c("fv-icon", { attrs: { icon: "search" } })],
                              1
                            ),
                            _vm.filtersAreSet
                              ? _c(
                                  "base-button",
                                  {
                                    attrs: { color: "danger" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.resetFilters()
                                      },
                                    },
                                  },
                                  [_c("fv-icon", { attrs: { icon: "close" } })],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                    !_vm.veterinarians.length && !_vm.loading
                      ? _c("div", [
                          _c("p", { staticClass: "text-sm" }, [
                            _vm._v(
                              " No vets found " +
                                _vm._s(
                                  _vm.search
                                    ? "with the current search string"
                                    : ""
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._l(_vm.veterinarians, function (vet) {
                  return _c(
                    "tr",
                    { key: vet.id, staticClass: "text-left" },
                    [
                      _c("table-data", [_vm._v(_vm._s(vet.id))]),
                      _c("table-data", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "focus:outline-none group flex items-center text-left",
                            on: {
                              click: function ($event) {
                                return _vm.goToVet(vet.id)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "hidden md:flex md:flex-shrink-0",
                              },
                              [
                                _c("user-avatar", {
                                  attrs: { size: 4, avatar: vet.avatarUrl },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "flex flex-col space-y-1 md:ml-3",
                                attrs: { "data-testid": "veterinarian-info" },
                              },
                              [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "font-semibold text-blueDark group-hover:text-fv-blue",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(vet.display_name || vet.name) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "text-sm group-hover:text-fv-blue",
                                  },
                                  [_vm._v(" " + _vm._s(vet.email) + " ")]
                                ),
                                _c("p", { staticClass: "text-sm" }, [
                                  _vm._v(" " + _vm._s(vet.mobile_phone) + " "),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _c(
                        "table-data",
                        {
                          staticClass:
                            "flex flex-col justify-center truncate text-sm",
                          class: { "align-top": vet.animal_types.length },
                          style: { maxWidth: "14rem" },
                        },
                        [
                          vet.animal_types.length
                            ? _c(
                                "div",
                                { staticClass: "flex flex-wrap" },
                                _vm._l(vet.animal_types, function (animalType) {
                                  return _c(
                                    "p",
                                    {
                                      key: animalType.id + vet.id,
                                      staticClass: "mr-2",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(animalType.name) + " "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _c("div", [_c("p", [_vm._v("-")])]),
                        ]
                      ),
                      _c("table-data", { staticClass: "align-top text-sm" }, [
                        _c(
                          "div",
                          { staticClass: "flex flex-col space-y-1" },
                          _vm._l(vet.roles, function (role) {
                            return _c("p", { key: role.id }, [
                              _vm._v(" " + _vm._s(role.display_name) + " "),
                            ])
                          }),
                          0
                        ),
                      ]),
                      _c(
                        "table-data",
                        {
                          staticClass: "text-sm",
                          class: { "align-top": vet.languages.length },
                        },
                        [
                          vet.languages.length
                            ? _c(
                                "div",
                                { staticClass: "space-y-1" },
                                _vm._l(vet.languages, function (language) {
                                  return _c("p", { key: language.id }, [
                                    _vm._v(" " + _vm._s(language.name) + " "),
                                  ])
                                }),
                                0
                              )
                            : _c("div", [_c("p", [_vm._v("-")])]),
                        ]
                      ),
                      _c("table-data", [
                        _vm.digitalClinicId
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "flex flex-col space-y-1 text-gray-800",
                              },
                              [
                                _c(
                                  "base-button",
                                  {
                                    attrs: { title: "Edit user" },
                                    on: {
                                      click: function ($event) {
                                        _vm.showEditModal(vet.id),
                                          (_vm.user = vet)
                                      },
                                    },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "hover:opacity-75",
                                      attrs: { icon: "user-edit" },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "base-button",
                                  {
                                    attrs: { title: "Remove user" },
                                    on: {
                                      click: function ($event) {
                                        ;(_vm.remove = true), (_vm.user = vet)
                                      },
                                    },
                                  },
                                  [
                                    _c("fv-icon", {
                                      staticClass: "h-4 w-4 hover:opacity-75",
                                      attrs: { icon: "close" },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]),
                    ],
                    1
                  )
                }),
                _vm.vetList
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "flex w-full items-baseline justify-between",
                        attrs: { slot: "tableFooter" },
                        slot: "tableFooter",
                      },
                      [
                        _vm.loading
                          ? _c("spinner-overlay", {
                              attrs: { loading: _vm.loading, size: "xxl" },
                            })
                          : _vm._e(),
                        _vm.veterinarians.length
                          ? _c(
                              "div",
                              { staticClass: "inline-flex space-x-4" },
                              [
                                _vm.vetList.prev_page_url ||
                                _vm.vetList.next_page_url
                                  ? _c("pagination-buttons", {
                                      attrs: {
                                        "pagination-list": _vm.vetList,
                                        loading: _vm.loading,
                                      },
                                      on: {
                                        previous: function ($event) {
                                          return _vm.fetch(
                                            _vm.vetList.prev_page_url
                                          )
                                        },
                                        next: function ($event) {
                                          return _vm.fetch(
                                            _vm.vetList.next_page_url
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _c("v-select", {
                                  staticClass: "v-input",
                                  attrs: {
                                    "append-to-body": true,
                                    position: "top",
                                    clearable: false,
                                    searchable: false,
                                    label: "name",
                                    reduce: function (opt) {
                                      return opt.id
                                    },
                                    options: _vm.paginationOptions,
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.fetch()
                                    },
                                  },
                                  model: {
                                    value: _vm.paginate,
                                    callback: function ($$v) {
                                      _vm.paginate = $$v
                                    },
                                    expression: "paginate",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("div", [
                          _vm._v(
                            " Viewing " +
                              _vm._s(_vm.vets.length) +
                              " of " +
                              _vm._s(_vm.vetList.total) +
                              " veterinarians "
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c(
        "base-modal",
        {
          attrs: { show: _vm.edit || _vm.remove, "show-close-button": false },
          on: {
            close: function ($event) {
              ;(_vm.edit = false), (_vm.remove = false)
            },
          },
        },
        [
          _vm._t("default", function () {
            return [
              _c("h2", { staticClass: "mb-5 text-xl" }, [
                _c("span", { staticClass: "font-black" }, [
                  _vm._v(_vm._s(_vm.edit ? "Edit" : "Remove") + " user"),
                ]),
                _vm._v(" - " + _vm._s(_vm.user.display_name) + " "),
              ]),
              _vm.edit && !_vm.modalLoading
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "mb-4" },
                      [
                        _c("h3", { staticClass: "mb-2 text-lg" }, [
                          _vm._v("Animal types"),
                        ]),
                        _vm._l(_vm.animalTypes, function (animalType) {
                          return _c(
                            "div",
                            {
                              key: animalType.id,
                              staticClass: "flex items-center",
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.animal_type_ids,
                                    expression: "animal_type_ids",
                                  },
                                  {
                                    name: "validate",
                                    rawName: "v-validate",
                                    value: "required",
                                    expression: "'required'",
                                  },
                                ],
                                staticClass: "mb-2 mr-4",
                                attrs: {
                                  id: "animal_type_ids-" + animalType.id,
                                  "data-vv-scope": "section-2",
                                  "data-vv-as": "animal type",
                                  type: "checkbox",
                                  name: "animal_type_ids",
                                },
                                domProps: {
                                  value: animalType.id,
                                  checked: Array.isArray(_vm.animal_type_ids)
                                    ? _vm._i(
                                        _vm.animal_type_ids,
                                        animalType.id
                                      ) > -1
                                    : _vm.animal_type_ids,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.animal_type_ids,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = animalType.id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.animal_type_ids = $$a.concat([
                                            $$v,
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.animal_type_ids = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.animal_type_ids = $$c
                                    }
                                  },
                                },
                              }),
                              _c(
                                "base-label",
                                {
                                  attrs: {
                                    for: "animal_type_ids-" + animalType.id,
                                  },
                                },
                                [_vm._v(" " + _vm._s(animalType.name) + " ")]
                              ),
                            ],
                            1
                          )
                        }),
                        _c("p", { staticClass: "text-red text-sm" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.errors.first("section-2.animal_type_ids")
                              ) +
                              " "
                          ),
                        ]),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "mb-4" }, [
                      _c("h3", { staticClass: "mb-2 text-lg" }, [
                        _vm._v("Permissions"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "flex max-h-full items-center" },
                        [
                          _c("div", { staticClass: "form-switch max-h-full" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.user.is_admin,
                                  expression: "user.is_admin",
                                },
                              ],
                              staticClass: "form-switch-checkbox",
                              attrs: {
                                id: "is_admin",
                                type: "checkbox",
                                name: "is_admin",
                              },
                              domProps: {
                                checked: Array.isArray(_vm.user.is_admin)
                                  ? _vm._i(_vm.user.is_admin, null) > -1
                                  : _vm.user.is_admin,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.user.is_admin,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.user,
                                          "is_admin",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.user,
                                          "is_admin",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.user, "is_admin", $$c)
                                  }
                                },
                              },
                            }),
                            _c("label", {
                              staticClass: "form-switch-label display-none",
                              attrs: { for: "is_admin" },
                            }),
                          ]),
                          _c(
                            "label",
                            {
                              staticClass:
                                "text-sm font-semibold text-gray-900",
                              attrs: { for: "is_admin" },
                            },
                            [_vm._v(" Admin ")]
                          ),
                        ]
                      ),
                      _c("p", { staticClass: "text-red text-sm" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.errors.first("section-3.is_admin")) +
                            " "
                        ),
                      ]),
                    ]),
                    _vm.errorMessages
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "my-4 flex flex-row rounded border border-red-500 bg-white p-2",
                          },
                          [
                            _c("font-awesome-icon", {
                              staticClass:
                                "text-red mr-4 ml-2 self-center text-lg",
                              attrs: { icon: "exclamation-circle" },
                            }),
                            _vm._l(_vm.errorMessages, function (error, index) {
                              return _c(
                                "p",
                                {
                                  key: index,
                                  staticClass: "self-center text-base",
                                },
                                _vm._l(error, function (e, i) {
                                  return _c("span", { key: i }, [
                                    _vm._v(_vm._s(e)),
                                  ])
                                }),
                                0
                              )
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm.remove && !_vm.modalLoading
                ? _c("div", [
                    _c(
                      "div",
                      { staticClass: "mb-4 flex items-center" },
                      [
                        _c("font-awesome-icon", {
                          staticClass: "text-red-dark mx-3",
                          attrs: { icon: "exclamation-triangle" },
                        }),
                        _c("p", { staticClass: "p-4" }, [
                          _vm._v(
                            " Are you sure you want to remove this user? The user will be removed from this clinic. "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]
          }),
          _vm.modalLoading
            ? _c(
                "div",
                { staticClass: "flex h-32 items-center justify-center" },
                [_c("base-spinner", { attrs: { size: "2x" } })],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "sticky bottom-0 p-2",
              attrs: { slot: "buttons" },
              slot: "buttons",
            },
            [
              _c(
                "base-button",
                {
                  staticClass: "mr-4 flex-1",
                  attrs: { color: "cancel" },
                  on: {
                    click: function ($event) {
                      ;(_vm.edit = false), (_vm.remove = false)
                    },
                  },
                },
                [_vm._v(" Cancel ")]
              ),
              _vm.edit
                ? _c(
                    "base-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "flex-1",
                      attrs: { disabled: _vm.loading },
                      on: { click: _vm.editUser },
                    },
                    [_vm._v(" Save ")]
                  )
                : _vm._e(),
              _vm.remove
                ? _c(
                    "base-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      staticClass: "bg-red-dark hover:bg-red-darker flex-1",
                      attrs: { color: "primary", disabled: _vm.loading },
                      on: { click: _vm.removeUser },
                    },
                    [_vm._v(" Yes, remove user ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }