<template>
  <div class="relative w-full">
    <div
      v-if="loading"
      class="pointer-events-none absolute inset-0 bg-white opacity-25"
    />
    <div class="flex flex-col space-y-4">
      <div class="flex items-center justify-between">
        <p class="font-display text-xl font-black">
          Upcoming and current shifts
        </p>
      </div>
      <div class="flex flex-col space-y-2">
        <custom-select
          v-if="services.length"
          size="sm"
          :options="services"
          :value="payload.serviceId"
          @change="(payload.serviceId = $event), fetchShifts()"
        />
        <base-button color="light" :loading="loading" @click="fetchShifts">
          Refresh
        </base-button>
      </div>
      <div
        class="flex flex-col space-y-1 overflow-hidden"
        style="max-height: 65vh"
      >
        <p v-if="errorMsg" class="text-red-700">
          {{ errorMsg }}
        </p>
        <ul v-if="shifts.length" class="flex flex-col space-y-2 overflow-auto">
          <AvailableShift
            v-for="shift in shifts"
            :key="shift.id"
            :shift="shift"
          />
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { formatInTimeZone } from 'date-fns-tz';
import { mapState } from 'vuex';
import AvailableShift from '@/components/admin/schedule/schedule-displays/AvailableShift';

export default defineComponent({
  props: {
    country: {
      type: Object,
      required: true,
    },
  },
  components: {
    AvailableShift,
  },
  data() {
    return {
      shifts: [],
      errorMsg: null,
      loading: false,
      payload: {
        serviceId: 1,
        countryId: this.country.id,
        timezone: this.country.timezone,
      },
    };
  },
  computed: {
    ...mapState('admin/schedule', ['services']),
  },
  methods: {
    async fetchShifts() {
      this.loading = true;
      this.errorMsg = null;
      this.shifts = [];
      try {
        const payload = {
          ...this.payload,
          fromDate: formatInTimeZone(
            new Date(),
            this.country.timeZone,
            'yyyy-MM-dd'
          ),
          fromTime: formatInTimeZone(
            new Date(),
            this.country.timeZone,
            'HH:mm'
          ),
          toDate: formatInTimeZone(
            new Date(),
            this.country.timeZone,
            'yyyy-MM-dd'
          ),
          toTime: '23:59',
        };
        const { data } = await axios.get('/schedule', {
          params: { ...payload },
        });
        if (!data.length) {
          throw new Error('No available shifts');
        }
        this.loading = false;
        this.shifts = data;
      } catch (error) {
        this.loading = false;
        this.errorMsg = error.message;
      }
    },
  },
  mounted() {
    this.fetchShifts();
  },
});
</script>
