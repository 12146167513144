<template>
  <div class="relative">
    <!-- <div
      v-if="loading"
      class="absolute inset-0 bg-white opacity-25 pointer-events-none"
    /> -->

    <div class="flex flex-col space-y-4">
      <div class="flex items-center justify-between">
        <p class="font-display text-xl font-semibold">Services</p>
        <span v-show="isDev" class="hidden text-sm italic xl:block"
          >POST {{ url }}</span
        >
      </div>
      <div class="flex flex-col space-y-2">
        <base-select
          :options="animalTypes"
          :value="payload.animalTypeID"
          @change="(payload.animalTypeID = $event), fetchData()"
        />
        <base-button
          :loading="loading"
          :disabled="loading"
          color="light"
          @click="fetchData"
        >
          Refresh
        </base-button>
      </div>
      <ul
        v-if="servicesWithSlot && servicesWithSlot.length"
        class="flex flex-col space-y-4"
      >
        <li
          v-for="service in servicesWithSlot"
          :key="service.id"
          class="flex flex-col space-y-2 border-b p-2 pb-4"
        >
          <div class="flex flex-col space-y-1">
            <p class="mb-1 text-lg font-semibold">
              {{ service.name }}
            </p>
            <p>
              {{
                service.service_translation
                  ? service.service_translation.description
                  : service.description
              }}
            </p>
            <!-- <ul
                  v-if="service.appointment_types"
                  class="text-sm"
                >
                  <li
                    v-for="appointmentType in service.appointment_types"
                    :key="appointmentType.id"
                  >
                    <p>
                      {{ appointmentType.name }}
                    </p>
                  </li>
                </ul> -->
          </div>
          <div v-if="service.nextAvailableSlot" class="mt-2">
            <p class="mb-1 text-xs uppercase tracking-wide text-gray-600">
              Next available slot
            </p>
            <BookableSlot
              :next-available-time="service.nextAvailableSlot"
              :date-time="
                formatInTimeZone(
                  new Date(service.nextAvailableSlot.dateTime.dateTime),
                  country.timeZone,
                  'HH:mm'
                )
              "
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
} from '@vue/composition-api';

import { useAvailability } from '@/composables/use-availability';
import { __DEV__ } from '@/constants';
import { mapState } from 'vuex';
import BookableSlot from '@/components/admin/schedule/schedule-displays/BookableSlot';
import { ScheduleApi } from '@/api';
import { formatInTimeZone } from 'date-fns-tz';

export default defineComponent({
  props: {
    country: {
      type: Object,
      required: true,
    },
    animalTypes: {
      type: Array,
      required: true,
    },
  },
  components: {
    BookableSlot,
  },
  setup(props) {
    const payload = reactive({
      insuranceId: 1,
      animalTypeId: 1,
      countryId: props.country.id,
    });

    const {
      result,
      fetchData,
      loadingState: loading,
      url,
    } = useAvailability(`api/services`, 'get', { params: payload });

    const isDev = computed(() => __DEV__);
    const servicesWithoutSlot = computed(() => result.value.services);

    onMounted(async () => {
      try {
        await fetchData();
      } catch (error) {
        console.error(error);
        loading.value = false;
      }
    });

    return { servicesWithoutSlot, loading, url, fetchData, isDev, payload };
  },
  data() {
    return {
      servicesWithSlot: [],
      formatInTimeZone,
    };
  },
  computed: {
    ...mapState('admin', ['services']),
  },
  watch: {
    async servicesWithoutSlot() {
      await this.fetchServiceInfo(this.servicesWithoutSlot);
    },
  },
  methods: {
    async fetchServiceInfo(services) {
      this.loading = true;
      services.map(service => {
        return ScheduleApi.fetchServiceInfo(service.id, {
          countryId: this.country.id,
          withNextAvailableSlot: 1,
          withSlotPrice: 1,
        })
          .then(res => {
            this.loading = false;
            this.servicesWithSlot.push(res.data.service);
          })
          .catch(e => {
            this.loading = false;
            this.error = e.message;
          });
      });
    },
  },
});
</script>
