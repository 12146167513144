var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative w-full" }, [
    _vm.loading
      ? _c("div", {
          staticClass:
            "pointer-events-none absolute inset-0 z-30 bg-white opacity-25",
        })
      : _vm._e(),
    _c("div", { staticClass: "flex flex-col space-y-4" }, [
      _c("div", { staticClass: "flex items-center justify-between" }, [
        _c("p", { staticClass: "font-display text-xl font-semibold" }, [
          _vm._v("Next available slot"),
        ]),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isDev,
                expression: "isDev",
              },
            ],
            staticClass: "hidden text-sm italic xl:block",
          },
          [_vm._v("GET " + _vm._s(_vm.url))]
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-col space-y-2" },
        [
          _vm.nextAvailableSlot
            ? _c("BookableSlot", {
                attrs: {
                  "next-available-time": _vm.nextAvailableSlot,
                  "date-time": _vm.format(
                    new Date(_vm.nextAvailableSlot.booking_datetime),
                    "HH:mm"
                  ),
                },
              })
            : _vm._e(),
          _c(
            "base-button",
            {
              attrs: { color: "light", loading: _vm.loading },
              on: { click: _vm.fetchData },
            },
            [_vm._v(" Refresh ")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }