<template>
  <div>
    <div class="flex w-full flex-wrap space-y-4 lg:space-y-0 lg:space-x-6">
      <div class="flex w-full flex-col space-y-4 lg:w-1/3">
        <card>
          <NextAvailableSlot :country="country" />
        </card>
        <card>
          <ServicesWithNextSlot
            :country="country"
            :animal-types="animalTypes"
          />
        </card>
      </div>
      <div class="flex w-full flex-col lg:w-1/3">
        <card>
          <AvailableShifts :country="country" />
        </card>
      </div>
      <div class="flex w-full flex-col lg:flex-1">
        <card>
          <AvailableSlots :animal-types="animalTypes" :country="country" />
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import NextAvailableSlot from '@/components/admin/schedule/schedule-displays/NextAvailableSlot.vue';
import ServicesWithNextSlot from '@/components/admin/schedule/schedule-displays/ServicesWithNextSlot.vue';
import AvailableSlots from '@/components/admin/schedule/schedule-displays/AvailableSlots.vue';
import AvailableShifts from '@/components/admin/schedule/schedule-displays/AvailableShifts.vue';
import { AnimalApi } from '@/api';

export default {
  components: {
    NextAvailableSlot,
    ServicesWithNextSlot,
    AvailableSlots,
    AvailableShifts,
  },
  props: {
    timezone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      nextAvailableSlot: null,
      loadingNextSlot: false,
      loadingServices: false,
      loadingTimes: false,
      animalTypes: [],
      servicesPayload: {
        insuranceID: 1,
        animalTypeID: 1,
      },
      slots: [],
      slotsPayload: {
        serviceID: 1,
        animalTypeID: 1,
        timezone: this.timezone,
      },
    };
  },
  computed: {
    ...mapGetters('admin', ['country']),
  },
  mounted() {
    Promise.all([this.fetchAnimalTypes(), this.fetchServices()]);
  },
  methods: {
    ...mapActions('admin/schedule', ['fetchServices']),
    async fetchAnimalTypes() {
      const { data } = await AnimalApi.fetchAnimalTypes('en');
      this.animalTypes = data.animal_types;
    },
  },
};
</script>
