<template>
  <div class="relative w-full">
    <div
      v-if="loading"
      class="pointer-events-none absolute inset-0 z-30 bg-white opacity-25"
    />
    <div class="flex flex-col space-y-4">
      <div class="flex items-center justify-between">
        <p class="font-display text-xl font-semibold">Next available slot</p>
        <span v-show="isDev" class="hidden text-sm italic xl:block"
          >GET {{ url }}</span
        >
      </div>

      <div class="flex flex-col space-y-2">
        <BookableSlot
          v-if="nextAvailableSlot"
          :next-available-time="nextAvailableSlot"
          :date-time="
            format(new Date(nextAvailableSlot.booking_datetime), 'HH:mm')
          "
        />
        <base-button color="light" :loading="loading" @click="fetchData">
          Refresh
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, computed } from '@vue/composition-api';
import { useAvailability } from '@/composables/use-availability';
import { __DEV__ } from '@/constants';
import { format } from 'date-fns';
import BookableSlot from '@/components/admin/schedule/schedule-displays/BookableSlot';

export default defineComponent({
  props: {
    country: {
      type: Object,
      required: true,
    },
  },
  components: {
    BookableSlot,
  },
  data() {
    return {
      format,
    };
  },
  setup(props) {
    const {
      result,
      fetchData,
      loadingState: loading,
      url,
    } = useAvailability(
      `api/appointmentdata/next/${props.country.id}`,
      'get',
      {}
    );
    const nextAvailableSlot = computed(() => result.value.next);
    const isDev = computed(() => __DEV__);
    onMounted(() => fetchData());
    return { nextAvailableSlot, fetchData, loading, url, isDev };
  },
});
</script>
