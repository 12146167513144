<template>
  <div
    v-if="nextAvailableTime && nextAvailableTime.dateTime"
    class="flex w-full items-center justify-between rounded-lg border py-4 px-4 hover:border-gray-500 hover:bg-gray-100"
    @click="$emit('next', nextAvailableTime)"
  >
    <div class="flex items-center">
      <UserAvatar :size="5" :avatar="avatar" />
      <div class="px-8">
        <h3 class="-mb-2 text-4xl font-bold">
          {{ dateTime }}
        </h3>
        <p class="text-base text-gray-600">
          {{ vet.display_name }}
        </p>
        <p
          v-if="paymentRequired"
          class="text-firstvet-peachdark mr-3 text-base font-bold"
        >
          {{ priceString }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import UserAvatar from '@/components/user/UserAvatar';

export default {
  components: {
    UserAvatar,
  },
  props: {
    nextAvailableTime: {
      type: Object,
      required: true,
    },
    dateTime: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      paymentRequired: true,
    };
  },
  computed: {
    ...mapState(['platform']),
    ...mapGetters('admin', ['countryTimezone']),
    avatarUrl() {
      let imageUrl;
      if (this.platform === 'sv') {
        imageUrl = process.env.VUE_APP_IMAGE_URL_SV;
      } else {
        imageUrl = process.env.VUE_APP_IMAGE_URL_INTL;
      }
      return `${imageUrl}${this.vet.avatar}`;
    },
    priceString() {
      /* eslint-disable camelcase */
      return `${this.nextAvailableTime.price.amount} ${this.nextAvailableTime.price.currency}`;
      // const { currency_code, currency, amount } = this.nextAvailableTime.price;
      // return currency_code && currency_code === 'USD'
      //   ? `${currency}${amount}`
      //   : `${amount} ${currency}`;
    },
    vet() {
      return this.nextAvailableTime.veterinary
        ? this.nextAvailableTime.veterinary
        : this.nextAvailableTime.user;
    },
    avatar() {
      return this.vet.avatarUrl;
    },
  },
};
</script>
