<template>
  <li
    class="flex flex-col justify-between space-y-2 rounded border bg-gray-50 py-2 px-1 lg:flex-wrap lg:space-x-6 lg:space-y-0"
  >
    <div
      class="inline-flex flex-col lg:flex-row lg:items-center lg:justify-between"
    >
      <div class="inline-flex items-center space-x-6">
        <div class="inline-flex flex-col space-y-1">
          <div class="relative">
            <span
              v-if="isActiveNow"
              class="absolute top-0 right-0 mb-px h-3 w-3 animate-ping rounded-full bg-green-500"
            />
            <span
              >{{ format(new Date(shift.start), 'HH:mm') }} -
              {{ format(new Date(shift.end), 'HH:mm') }}</span
            >
          </div>
          <p class="text-xs text-gray-600">
            {{ shift.timezone }}
          </p>
        </div>
        <div class="font-semibold">
          {{ shift.user.display_name }}
        </div>
      </div>
      <div class="text-sm text-gray-700 lg:text-right">
        <p v-if="service" class="hidden lg:block">
          {{ service.name }}
        </p>
      </div>
    </div>
  </li>
</template>

<script>
import { format, isWithinInterval } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { mapState } from 'vuex';

export default {
  props: {
    shift: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      format,
    };
  },

  computed: {
    ...mapState('admin/schedule', ['services']),
    isActiveNow() {
      return isWithinInterval(new Date(), {
        start: new Date(
          zonedTimeToUtc(new Date(this.shift.start), this.shift.timezone)
        ),
        end: new Date(
          zonedTimeToUtc(new Date(this.shift.end), this.shift.timezone)
        ),
      });
    },
    service() {
      return this.services.find(
        service => service.id === this.shift.service_id
      );
    },
  },
};
</script>
