var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative w-full" }, [
    _vm.loading
      ? _c("div", {
          staticClass:
            "pointer-events-none absolute inset-0 bg-white opacity-25",
        })
      : _vm._e(),
    _c("div", { staticClass: "flex flex-col space-y-4" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "flex flex-col space-y-2" },
        [
          _vm.services.length
            ? _c("custom-select", {
                attrs: {
                  size: "sm",
                  options: _vm.services,
                  value: _vm.payload.serviceId,
                },
                on: {
                  change: function ($event) {
                    ;(_vm.payload.serviceId = $event), _vm.fetchShifts()
                  },
                },
              })
            : _vm._e(),
          _c(
            "base-button",
            {
              attrs: { color: "light", loading: _vm.loading },
              on: { click: _vm.fetchShifts },
            },
            [_vm._v(" Refresh ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-col space-y-1 overflow-hidden",
          staticStyle: { "max-height": "65vh" },
        },
        [
          _vm.errorMsg
            ? _c("p", { staticClass: "text-red-700" }, [
                _vm._v(" " + _vm._s(_vm.errorMsg) + " "),
              ])
            : _vm._e(),
          _vm.shifts.length
            ? _c(
                "ul",
                { staticClass: "flex flex-col space-y-2 overflow-auto" },
                _vm._l(_vm.shifts, function (shift) {
                  return _c("AvailableShift", {
                    key: shift.id,
                    attrs: { shift: shift },
                  })
                }),
                1
              )
            : _vm._e(),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "flex items-center justify-between" }, [
      _c("p", { staticClass: "font-display text-xl font-black" }, [
        _vm._v(" Upcoming and current shifts "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }