<template>
  <div class="">
    <div class="flex flex-1 flex-col">
      <div class="mb-4 flex justify-between">
        <table-container
          :theads="['ID', 'Details', 'Animal types', 'Roles', 'Languages', '']"
          class="min-h-screen"
        >
          <div slot="tableHeader" class="flex w-full flex-col space-y-4 pt-2">
            <div class="flex w-full flex-wrap md:flex-nowrap md:space-x-8">
              <div v-if="digitalClinicId" class="flex">
                <base-button
                  color="primary"
                  :to="{ name: 'invite-user' }"
                  class="flex items-center"
                >
                  Invite user <font-awesome-icon icon="plus" class="ml-2" />
                </base-button>
              </div>
              <div class="mt-2 w-full md:mt-0 md:max-w-sm">
                <custom-select
                  v-model="selectedService"
                  :options="serviceOptions"
                  @change="fetch()"
                />
              </div>
              <div class="mt-2 w-full md:mt-0 md:max-w-sm">
                <custom-select
                  v-model="selectedAnimalType"
                  :options="animalTypeOptions"
                  @change="fetch()"
                />
              </div>
              <form
                class="inline-flex items-center space-x-1"
                @submit.prevent="fetch()"
              >
                <base-input
                  id="search"
                  ref="searchInput"
                  v-model="searchString"
                  name="search"
                  placeholder="Search"
                  class="min-w-80"
                />
                <base-button
                  color="dark"
                  :loading="loading"
                  data-testid="search-button"
                  @click="fetch()"
                >
                  <fv-icon icon="search" />
                </base-button>
                <base-button
                  v-if="filtersAreSet"
                  color="danger"
                  @click="resetFilters()"
                >
                  <fv-icon icon="close" />
                </base-button>
              </form>
            </div>
            <div v-if="!veterinarians.length && !loading">
              <p class="text-sm">
                No vets found
                {{ search ? 'with the current search string' : '' }}
              </p>
            </div>
          </div>

          <tr v-for="vet in veterinarians" :key="vet.id" class="text-left">
            <table-data>{{ vet.id }}</table-data>

            <table-data>
              <button
                class="focus:outline-none group flex items-center text-left"
                @click="goToVet(vet.id)"
              >
                <span class="hidden md:flex md:flex-shrink-0">
                  <user-avatar class="" :size="4" :avatar="vet.avatarUrl" />
                </span>
                <div
                  class="flex flex-col space-y-1 md:ml-3"
                  data-testid="veterinarian-info"
                >
                  <p
                    class="font-semibold text-blueDark group-hover:text-fv-blue"
                  >
                    {{ vet.display_name || vet.name }}
                  </p>
                  <p class="text-sm group-hover:text-fv-blue">
                    {{ vet.email }}
                  </p>
                  <p class="text-sm">
                    {{ vet.mobile_phone }}
                  </p>
                </div>
              </button>
            </table-data>

            <table-data
              class="flex flex-col justify-center truncate text-sm"
              :class="{ 'align-top': vet.animal_types.length }"
              :style="{ maxWidth: '14rem' }"
            >
              <div v-if="vet.animal_types.length" class="flex flex-wrap">
                <p
                  v-for="animalType in vet.animal_types"
                  :key="animalType.id + vet.id"
                  class="mr-2"
                >
                  {{ animalType.name }}
                </p>
              </div>
              <div v-else>
                <p>-</p>
              </div>
            </table-data>
            <table-data class="align-top text-sm">
              <div class="flex flex-col space-y-1">
                <p v-for="role in vet.roles" :key="role.id">
                  {{ role.display_name }}
                </p>
              </div>
            </table-data>
            <table-data
              class="text-sm"
              :class="{ 'align-top': vet.languages.length }"
            >
              <div v-if="vet.languages.length" class="space-y-1">
                <p v-for="language in vet.languages" :key="language.id">
                  {{ language.name }}
                </p>
              </div>
              <div v-else>
                <p>-</p>
              </div>
            </table-data>
            <table-data>
              <div
                v-if="digitalClinicId"
                class="flex flex-col space-y-1 text-gray-800"
              >
                <base-button
                  title="Edit user"
                  @click="showEditModal(vet.id), (user = vet)"
                >
                  <font-awesome-icon
                    icon="user-edit"
                    class="hover:opacity-75"
                  />
                </base-button>
                <base-button
                  title="Remove user"
                  @click="(remove = true), (user = vet)"
                >
                  <fv-icon icon="close" class="h-4 w-4 hover:opacity-75" />
                </base-button>
              </div>
            </table-data>
          </tr>
          <div
            v-if="vetList"
            slot="tableFooter"
            class="flex w-full items-baseline justify-between"
          >
            <spinner-overlay v-if="loading" :loading="loading" size="xxl" />
            <div v-if="veterinarians.length" class="inline-flex space-x-4">
              <pagination-buttons
                v-if="vetList.prev_page_url || vetList.next_page_url"
                :pagination-list="vetList"
                :loading="loading"
                @previous="fetch(vetList.prev_page_url)"
                @next="fetch(vetList.next_page_url)"
              />
              <v-select
                v-model="paginate"
                class="v-input"
                :append-to-body="true"
                position="top"
                :clearable="false"
                :searchable="false"
                label="name"
                :reduce="opt => opt.id"
                :options="paginationOptions"
                @input="fetch()"
              />
            </div>
            <div>
              Viewing {{ vets.length }} of {{ vetList.total }} veterinarians
            </div>
          </div>
        </table-container>
      </div>
    </div>
    <base-modal
      :show="edit || remove"
      :show-close-button="false"
      @close="(edit = false), (remove = false)"
    >
      <slot>
        <h2 class="mb-5 text-xl">
          <span class="font-black">{{ edit ? 'Edit' : 'Remove' }} user</span> -
          {{ user.display_name }}
        </h2>
        <div v-if="edit && !modalLoading">
          <div class="mb-4">
            <h3 class="mb-2 text-lg">Animal types</h3>
            <div
              v-for="animalType in animalTypes"
              :key="animalType.id"
              class="flex items-center"
            >
              <input
                :id="`animal_type_ids-${animalType.id}`"
                v-model="animal_type_ids"
                v-validate="'required'"
                data-vv-scope="section-2"
                data-vv-as="animal type"
                type="checkbox"
                name="animal_type_ids"
                class="mb-2 mr-4"
                :value="animalType.id"
              />
              <base-label :for="`animal_type_ids-${animalType.id}`">
                {{ animalType.name }}
              </base-label>
            </div>
            <p class="text-red text-sm">
              {{ errors.first('section-2.animal_type_ids') }}
            </p>
          </div>
          <div class="mb-4">
            <h3 class="mb-2 text-lg">Permissions</h3>
            <div class="flex max-h-full items-center">
              <div class="form-switch max-h-full">
                <input
                  id="is_admin"
                  v-model="user.is_admin"
                  type="checkbox"
                  name="is_admin"
                  class="form-switch-checkbox"
                />
                <label class="form-switch-label display-none" for="is_admin" />
              </div>
              <label class="text-sm font-semibold text-gray-900" for="is_admin">
                Admin
              </label>
            </div>
            <p class="text-red text-sm">
              {{ errors.first('section-3.is_admin') }}
            </p>
          </div>
          <div
            v-if="errorMessages"
            class="my-4 flex flex-row rounded border border-red-500 bg-white p-2"
          >
            <font-awesome-icon
              icon="exclamation-circle"
              class="text-red mr-4 ml-2 self-center text-lg"
            />
            <p
              v-for="(error, index) in errorMessages"
              :key="index"
              class="self-center text-base"
            >
              <span v-for="(e, i) in error" :key="i">{{ e }}</span>
            </p>
          </div>
        </div>
        <div v-if="remove && !modalLoading">
          <div class="mb-4 flex items-center">
            <font-awesome-icon
              icon="exclamation-triangle"
              class="text-red-dark mx-3"
            />
            <p class="p-4">
              Are you sure you want to remove this user? The user will be
              removed from this clinic.
            </p>
          </div>
        </div>
      </slot>
      <div v-if="modalLoading" class="flex h-32 items-center justify-center">
        <base-spinner size="2x" />
      </div>
      <div slot="buttons" class="sticky bottom-0 p-2">
        <base-button
          color="cancel"
          class="mr-4 flex-1"
          @click="(edit = false), (remove = false)"
        >
          Cancel
        </base-button>
        <base-button
          v-if="edit"
          v-loading="loading"
          class="flex-1"
          :disabled="loading"
          @click="editUser"
        >
          Save
        </base-button>
        <base-button
          v-if="remove"
          v-loading="loading"
          color="primary"
          class="bg-red-dark hover:bg-red-darker flex-1"
          :disabled="loading"
          @click="removeUser"
        >
          Yes, remove user
        </base-button>
      </div>
    </base-modal>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import UserAvatar from '@/components/user/UserAvatar.vue';
import PaginationButtons from '@/UI/PaginationButtons.vue';
import DigitalClinicApi from '@/api/modules/admin/digital-clinic';
import { ScheduleApi } from '@/api';

export default {
  components: {
    UserAvatar,
    PaginationButtons,
  },
  data() {
    return {
      loading: false,
      modalLoading: false,
      edit: false,
      remove: false,
      is_admin: false,
      animal_type_ids: [],
      user: {},

      searchString: '',
      selectedService: null,
      selectedAnimalType: null,
      errorMessages: null,

      vetList: {
        data: [],
      },
      vets: [],
      paginate: 10,
      paginationOptions: [
        { id: 5, name: '5' },
        { id: 10, name: '10' },
        { id: 20, name: '20' },
        { id: 30, name: '30' },
        { id: 50, name: 50 },
        { id: 100, name: 100 },
      ],
      search: '',
    };
  },
  computed: {
    ...mapGetters('auth', ['isDigitalClinicUser', 'isAdmin']),
    ...mapGetters('admin/digital-clinic', {
      digitalClinicUsers: 'getDigitalClinicUsers',
      digitalClinicId: 'getDigitalClinicId',
    }),
    ...mapGetters('animal', { animalTypes: 'getAnimalTypes' }),

    ...mapState('admin/schedule', ['services']),
    ...mapState('animal', ['animalTypes']),
    ...mapState('admin', ['countryId']),
    veterinarians() {
      if (!this.vets.length) return [];
      if (this.digitalClinicId) {
        return this.digitalClinicUsers;
      }
      return this.vets.filter(vet => vet.id);
    },
    serviceOptions() {
      return [{ id: null, name: 'All services' }, ...this.services];
    },
    animalTypeOptions() {
      return [{ id: null, name: 'Any animal type' }, ...this.animalTypes];
    },

    filtersAreSet() {
      return (
        !!this.selectedAnimalType || !!this.search || !!this.selectedService
      );
    },
  },
  mounted() {
    this.$refs.searchInput.$el.focus();
    const promises = [];
    promises.push(this.fetchServices());
    if (!this.animalTypes.length) {
      promises.push(this.fetchAnimalTypes());
    }

    Promise.all(promises);
    this.fetch();
  },
  methods: {
    ...mapActions('admin/schedule', ['fetchServices']),
    ...mapActions('admin/digital-clinic', [
      'fetchUsersForClinic',
      'fetchClinicUser',
    ]),
    ...mapActions('animal', ['fetchAnimalTypes']),
    async fetch(url = null) {
      this.search = this.searchString;
      const promises = [];
      if (this.digitalClinicId) {
        promises.push(this.fetchUsersForClinic());
      } else if (url) {
        this.loading = true;
        const { data } = await this.$axios.get(`${url}`);
        this.vetList = data;
        this.vets = this.vetList.data;
        this.loading = false;
      } else {
        promises.push(
          this.fetchVets({
            serviceId: this.selectedService,
            animalTypeId: this.selectedAnimalType,
            search: this.search,
            paginate: this.paginate,
          })
        );
      }

      this.loading = true;
      await Promise.all(promises);
      this.loading = false;
    },
    formatData(userTypes, allTypes) {
      // Format the data to arrays and compare them
      const userData = userTypes.map(type => type.id);
      const allData = allTypes.map(type => type.id);
      return userData.filter(id => allData.includes(id));
    },
    showEditModal(vetId) {
      this.modalLoading = true;
      this.edit = true;

      this.fetchClinicUser({
        digitalClinicId: this.digitalClinicId,
        userId: vetId,
      })
        .then(res => {
          this.user = { ...this.user, ...res };

          // Set which animal types should be pre selected
          this.animal_type_ids = this.formatData(
            this.user.animal_types,
            this.animalTypes
          );
        })
        .finally(() => {
          this.modalLoading = false;
        });
    },
    editUser() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.errorMessages = null;
          this.loading = true;
          const data = {
            display_name: this.user.display_name,
            is_admin: this.user.is_admin,
            animal_type_ids: this.animal_type_ids,
          };

          DigitalClinicApi.updateUser({
            digitalClinicId: this.digitalClinicId,
            userId: this.user.id,
            data,
          })
            .then(() => {
              this.fetchUsersForClinic();

              this.$notify({
                group: 'primary',
                title: 'User updated!',
                text: 'The user was successfully updated',
              });
              this.edit = false;
            })
            .catch(e => {
              console.log({ e });
              this.$notify({
                group: 'error',
                title: 'Something went wrong!',
                text: 'Please try again',
              });
              this.errorMessages = e.response.data.errors;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    removeUser() {
      this.loading = true;
      return DigitalClinicApi.removeUser({
        digitalClinicId: this.digitalClinicId,
        userId: this.user.id,
      })
        .then(() => {
          this.remove = false;
          this.$notify({
            group: 'primary',
            title: 'User updated!',
            text: 'The user was successfully removed',
          });

          this.fetchUsersForClinic();
        })
        .catch(e => {
          console.log({ e });
          this.$notify({
            group: 'error',
            title: 'Something went wrong!',
            text: 'Please try again',
          });
          this.errorMessages = e.response.data.errors;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async fetchVets(params) {
      try {
        const { data } = await ScheduleApi.fetchScheduleVets({
          ...params,
          countryId: this.countryId,
        });
        this.vetList = data;
        this.vets = this.vetList.data;
      } catch (error) {
        console.log(error);
        this.uiState = 'idle';
      }
    },
    resetFilters() {
      this.search = '';
      this.searchString = '';
      this.selectedAnimalType = null;
      this.selectedService = null;
      this.fetch();
    },
    goToVet(id) {
      this.$router.push({ name: 'admin-account', params: { id } });
    },
  },
};
</script>
