var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    {
      staticClass:
        "flex flex-col justify-between space-y-2 rounded border bg-gray-50 py-2 px-1 lg:flex-wrap lg:space-x-6 lg:space-y-0",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "inline-flex flex-col lg:flex-row lg:items-center lg:justify-between",
        },
        [
          _c("div", { staticClass: "inline-flex items-center space-x-6" }, [
            _c("div", { staticClass: "inline-flex flex-col space-y-1" }, [
              _c("div", { staticClass: "relative" }, [
                _vm.isActiveNow
                  ? _c("span", {
                      staticClass:
                        "absolute top-0 right-0 mb-px h-3 w-3 animate-ping rounded-full bg-green-500",
                    })
                  : _vm._e(),
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.format(new Date(_vm.shift.start), "HH:mm")) +
                      " - " +
                      _vm._s(_vm.format(new Date(_vm.shift.end), "HH:mm"))
                  ),
                ]),
              ]),
              _c("p", { staticClass: "text-xs text-gray-600" }, [
                _vm._v(" " + _vm._s(_vm.shift.timezone) + " "),
              ]),
            ]),
            _c("div", { staticClass: "font-semibold" }, [
              _vm._v(" " + _vm._s(_vm.shift.user.display_name) + " "),
            ]),
          ]),
          _c("div", { staticClass: "text-sm text-gray-700 lg:text-right" }, [
            _vm.service
              ? _c("p", { staticClass: "hidden lg:block" }, [
                  _vm._v(" " + _vm._s(_vm.service.name) + " "),
                ])
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }