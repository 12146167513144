var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "flex w-full flex-wrap space-y-4 lg:space-y-0 lg:space-x-6",
      },
      [
        _c(
          "div",
          { staticClass: "flex w-full flex-col space-y-4 lg:w-1/3" },
          [
            _c(
              "card",
              [_c("NextAvailableSlot", { attrs: { country: _vm.country } })],
              1
            ),
            _c(
              "card",
              [
                _c("ServicesWithNextSlot", {
                  attrs: {
                    country: _vm.country,
                    "animal-types": _vm.animalTypes,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex w-full flex-col lg:w-1/3" },
          [
            _c(
              "card",
              [_c("AvailableShifts", { attrs: { country: _vm.country } })],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "flex w-full flex-col lg:flex-1" },
          [
            _c(
              "card",
              [
                _c("AvailableSlots", {
                  attrs: {
                    "animal-types": _vm.animalTypes,
                    country: _vm.country,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }